import React from "react"
import Layout from "../components/Layout"
import LessonList from "../components/lessons/LessonList"
import Menu from "../components/lessons/Menu"
import Schedule from "../components/lessons/Schedule"
import Seo from "../components/Seo"

const lessons = () => {
  return (
    <Layout>
      <Seo title="レッスン・料金" />
      <Menu />
      <LessonList />
      {/* <Schedule /> */}
    </Layout>
  )
}

export default lessons
