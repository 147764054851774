import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import HeadlineM from "../HeadlineM"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { AnimatedOnScroll } from "react-animated-css-onscroll"

const query = graphql`
  query {
    kangaroo: file(relativePath: { eq: "animal/kangaroo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    emu: file(relativePath: { eq: "animal/emu.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    tasmanianDevil: file(relativePath: { eq: "animal/tasmanian-devil.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    koala: file(relativePath: { eq: "animal/koala.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    platypus: file(relativePath: { eq: "animal/platypus.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const LessonList = () => {
  const data = useStaticQuery(query)
  const items = [
    // {
    //   id: "kids",
    //   title: "幼児（3〜6歳）コース",
    //   subTitle:
    //     "吸収力抜群のうちに英語に慣れ、聞いた音を真似っこして流暢な英語を話せるように",
    //   time: "1レッスン40分",
    //   price: "月額6,000円（週1回・年44回）",
    //   numberOfPeople: "2〜4名",
    //   desc: "ゲームをしたり、歌を歌ったり、さまざまなアクティビティを全て英語で行います。小さなうちから良質な英語にたっぷり触れて耳を慣れさせることで、流暢に話せるためのスタートダッシュになります。",
    //   image: getImage(data.kangaroo),
    // },
    {
      id: "elementally-school",
      title: "小学生コース",
      subTitle:
        "「英語を学ぶ楽しさ」に触れながら、ぐんぐんと身近なことが話せるように",
      time: "1レッスン50分",
      price: "月額7,000円（週1回・年44回）",
      numberOfPeople: "2〜4名",
      desc: "楽しいゲームなども取り入れながら身近なものについての簡単な会話ができるように目指します。吸収力がある子どものうちから英語でコミュニケーションを取ることに慣れせ、ライティング・リスニング・リーディング・スピーキングの４つのスキルを伸ばしていきます。",
      image: getImage(data.emu),
    },
    {
      id: "junior-high-school",
      title: "中学生コース",
      subTitle: "英語を使いこなし、グローバル社会で生き抜く力を培えるように",
      time: "1レッスン50分",
      price: "月額7,500円（週1回・年44回）",
      numberOfPeople: "2〜4名",
      desc: "学校などで習った文法や単語をどんどんアウトプットして、スピーキング・リスニング・ライティング・リーディングを伸ばしていきます。ディスカッションや自分の考えを話す場を取り入れて、自信を持って英語を使いこなせるようにしていきます。",
      image: getImage(data.tasmanianDevil),
    },
    {
      id: "adults",
      title: "大人コース",
      subTitle:
        "初心者から経験者まで、それぞれの目標のレベルを達成できるように",
      time: "1レッスン50分",
      price: "月額8,500円（週1回・年44回）",
      numberOfPeople: "2〜4名",
      desc: "毎回のレッスンでゴールを設定して言語を習得する手法（Task-based learning）を活用して、効率よく目標のレベルに到達することを目指します。日常からビジネスまであらゆるシチュエーションに応じたレッスンを用意します。",
      image: getImage(data.koala),
    },
    {
      id: "private",
      title: "プライベート",
      subTitle: "自分に最適な学習プランを好きなときに、好きなペースで",
      time: "1レッスン50分",
      numberOfPeople: "マンツーマン",
      priceDetail: "",
      desc: "マンツーマンの個人レッスンで、より集中的に学習ができます。目標や目的に応じて先生が全てオーダーメイドでレッスンをつくるので自分に最適なプランで学習することができます。毎週決まった時間が取れない方も自分のペースで受講していくことができます。",
      image: getImage(data.platypus),
    },
  ]

  const privateFee = [
    { length: "1ヶ月（4回）", fee: "¥14,000（1回あたり ￥3,500）" },
    { length: "3ヶ月（12回）", fee: "¥40,000（1回あたり ￥3,333）" },
    { length: "6ヶ月（24回）", fee: "¥75,000（1回あたり ￥3,125）" },
    { length: "12ヶ月（48回）", fee: "¥140,000（1回あたり ￥2,916）" },
  ]

  return (
    <div className="mt-16">
      {items.map(item => {
        return (
          <div id={item.id} key={item.title} className="pt-8">
            <HeadlineM title={item.title} />
            <div className="py-10 mt-8 mx-[calc(50%_-_50vw)] bg-LightYellow px-5">
              <div className="items-center max-w-5xl grid-cols-2 mx-auto md:gap-8 md:grid">
                <AnimatedOnScroll animationIn="animate__rubberBand">
                  <div className="h-40 max-w-[250px] mx-auto">
                    <GatsbyImage
                      image={item.image}
                      alt={item.title}
                      objectFit="contain"
                      className="h-40 max-w-[250px]"
                    />
                  </div>
                </AnimatedOnScroll>
                <div className="mt-8 space-y-5 md:mt-0">
                  <p className="text-xl text-center md:text-2xl">
                    {item.subTitle}
                  </p>
                  <div>
                    <p className="font-bold">
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="mr-2"
                        size="xs"
                      />
                      {item.time}
                    </p>
                    {item.price && (
                      <p className="font-bold">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="mr-2"
                          size="xs"
                        />
                        {item.price}
                      </p>
                    )}
                    <p className="font-bold">
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="mr-2"
                        size="xs"
                      />
                      {item.numberOfPeople}
                    </p>
                  </div>
                  {item.id === "private" && (
                    <div>
                      <div>料金表</div>
                      {privateFee.map(item => (
                        <div
                          key={item.length}
                          className="grid-cols-3 mt-3 md:grid md:mt-0"
                        >
                          <div className="col-span-1">{item.length}</div>
                          <div className="col-span-2">{item.fee}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  <p>{item.desc}</p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default LessonList
