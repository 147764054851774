import { Link } from "gatsby"
import React from "react"
import { PATH } from "../../constants"

const Menu = () => {
  const items = [
    // { title: "幼児", path: `${PATH.LESSONS}#kids` },
    { title: "小学生", path: `${PATH.LESSONS}#elementally-school` },
    { title: "中学生", path: `${PATH.LESSONS}#junior-high-school` },
    { title: "大人", path: `${PATH.LESSONS}#adults` },
    { title: "プライベート", path: `${PATH.LESSONS}#private` },
    // { title: "スケジュール", path: `${PATH.LESSONS}#schedule` },
  ]
  return (
    <>
      <div className="grid grid-cols-2 gap-3 mt-16 md:gap-5 md:grid-cols-3">
        {items.map(item => {
          return (
            <Link
              key={item.title}
              to={item.path}
              className={`p-5 text-lg text-center md:text-xl font-ZenMaruGathic ${
                item.title === "スケジュール" ? "bg-Yellow" : "bg-LightGreen"
              }`}
            >
              {item.title}
            </Link>
          )
        })}
      </div>
    </>
  )
}

export default Menu
